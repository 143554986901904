import { gql } from '@apollo/client';
import { omit } from 'lodash';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const EXAM_TIER_ARGS = { examId: 'ID' };
const LIST_ARGS = { ...omit(COMMON_LIST_ARGS, ['fromDate', 'toDate']), ...EXAM_TIER_ARGS };
const SEARCH_ARGS = { ...omit(COMMON_SEARCH_ARGS, ['fromDate', 'toDate']), ...EXAM_TIER_ARGS };

const commonFields = `
  id
  name {
    en
  }
  status
  examId
  sections {
    description {
      en
    }
    heading {
      en
    }
    quizIds
    chapterTestIds
    sectionalTestIds
    postIds
    videoIds
  }
`;
const GET = gql`
  query GetExamTier($id: ID!) {
    examTier(id: $id) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListExamTiers${toGqlVariables(LIST_ARGS)} {
    items: examTiers${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        name {
          en
        }
        status
        sections {
          heading {
            en
          }
        }
        exam {
          id
          shortName {
            en
          }
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateExamTier($input: CreateExamTierInput!) {
    createExamTier(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateExamTier($input: UpdateExamTierInput!) {
    updateExamTier(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchExamTier${toGqlVariables(SEARCH_ARGS)} {
    items: examTiers${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

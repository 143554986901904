import React, { lazy, useReducer, useCallback, useEffect, LazyExoticComponent } from 'react';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { last } from 'lodash';
import { Button } from 'components';

export type DrawerTypes =
  | 'Exam'
  | 'ExamCategory'
  | 'User'
  | 'Subject'
  | 'Topic'
  | 'Post'
  | 'PushNotification'
  | 'Notification'
  | 'Question'
  | 'ChildQuestion'
  | 'Instruction'
  | 'TestSeries'
  | 'Faq'
  | 'Ticket'
  | 'Trending'
  | 'Banner'
  | 'Video'
  | 'VideoPlaylist'
  | 'Concept'
  | 'Formula'
  | 'Instructor'
  | 'Subtopic'
  | 'ShortUrl'
  | 'Chapter'
  | 'ExamTier'
  | 'Magazine'
  | 'PYPPdf'
  | 'LiveClass'
  | 'Batch'
  | 'PayoutHistory'
  | 'ProblemReport'
  | 'EBook'
  | 'NcertSolution'
  | 'LeadDetail'
  | 'Lead'
  | 'Referral'
  | 'Redeem'
  | 'PaymentLink'
  | 'Topper';

export type DrawerChild = {
  component: LazyExoticComponent<(props: DrawerChildComponentProps) => JSX.Element>;
  buttons?: TitleButtonType[];
  title?: string | JSX.Element;
};

export const drawerMap: Record<DrawerTypes, DrawerChild> = {
  Exam: {
    component: lazy(() => import(/* webpackChunkName: "Exam" */ 'views/apps/Exam/AddExam')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Subject')}>Add Subject</Button.Create>
      ),
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('ExamTier')}>Add Exam Tier</Button.Create>
      ),
    ],
  },
  ExamCategory: {
    component: lazy(
      () =>
        import(/* webpackChunkName: "ExamCategory" */ 'views/apps/ExamCategory/AddExamCategory'),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Exam')}>Add Exam</Button.Create>
      ),
    ],
  },
  User: {
    component: lazy(() => import(/* webpackChunkName: "User" */ 'views/apps/User/AddUser')),
  },
  Post: {
    component: lazy(() => import(/* webpackChunkName: "Post" */ 'views/apps/Post/AddPost')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Exam')}>Add Exam</Button.Create>
      ),
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Topic')}>Add Topic</Button.Create>
      ),
    ],
  },
  Notification: {
    component: lazy(
      () =>
        import(/* webpackChunkName: "Notification" */ 'views/apps/Notification/AddNotification'),
    ),
  },
  Subject: {
    component: lazy(
      () => import(/* webpackChunkName: "Subject" */ 'views/apps/Subject/AddSubject'),
    ),
  },
  PushNotification: {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "PushNotification" */ 'views/apps/PushNotification/AddPushNotification'
        ),
    ),
  },
  Topic: {
    component: lazy(() => import(/* webpackChunkName: "Topic" */ 'views/apps/Topic/AddTopic')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Subject')}>Add Subject</Button.Create>
      ),
    ],
  },
  Question: {
    component: lazy(
      () => import(/* webpackChunkName: "Question" */ 'views/apps/Question/AddQuestion'),
    ),
  },
  ChildQuestion: {
    component: lazy(
      () => import(/* webpackChunkName: "ChildQuestion" */ 'views/apps/Question/AddChildQuestion'),
    ),
    title: 'Add Child Questions',
  },
  Instruction: {
    component: lazy(
      (/* webpackChunkName: "Instruction" */) => import('views/apps/Instruction/AddInstruction'),
    ),
  },
  TestSeries: {
    component: lazy(
      () => import(/* webpackChunkName: "TestSeries" */ 'views/apps/TestSeries/AddTestSeries'),
    ),
  },
  Faq: {
    component: lazy(() => import(/* webpackChunkName: "Faq" */ 'views/apps/Faq/AddFaq')),
  },
  Ticket: {
    component: lazy(
      () => import(/* webpackChunkName: "Ticket" */ 'views/apps/Ticket/TicketAction'),
    ),
    title: 'Ticket Action',
  },
  Trending: {
    component: lazy(
      () => import(/* webpackChunkName: "Trending" */ 'views/apps/Trending/AddTrending'),
    ),
    title: 'Edit Trending Search',
  },
  Banner: {
    component: lazy(() => import(/* webpackChunkName: "Banner" */ 'views/apps/Banner/AddBanner')),
  },
  Video: {
    component: lazy(() => import(/* webpackChunkName: "Video" */ 'views/apps/Video/AddVideo')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Instructor')}>Add Instructor</Button.Create>
      ),
    ],
  },
  VideoPlaylist: {
    component: lazy(
      () =>
        import(/* webpackChunkName: "VideoPlaylist" */ 'views/apps/VideoPlaylist/AddVideoPlaylist'),
    ),
  },
  Concept: {
    component: lazy(
      () => import(/* webpackChunkName: "Concept" */ 'views/apps/Concept/AddConcept'),
    ),
  },
  Formula: {
    component: lazy(
      () => import(/* webpackChunkName: "Formula" */ 'views/apps/Formula/AddFormula'),
    ),
  },
  Instructor: {
    component: lazy(
      () => import(/* webpackChunkName: "Instructor" */ 'views/apps/Instructor/AddInstructor'),
    ),
  },
  Subtopic: {
    component: lazy(
      () => import(/* webpackChunkName: "Subtopic" */ 'views/apps/Subtopic/AddSubtopic'),
    ),
  },
  ShortUrl: {
    title: 'Create Short Url',
    component: lazy(
      () => import(/* webpackChunkName: "Subtopic" */ 'views/apps/ShortUrl/ShortUrlForm'),
    ),
  },
  Chapter: {
    component: lazy(
      () => import(/* webpackChunkName: "Chapter" */ 'views/apps/Chapter/AddChapter'),
    ),
  },
  ExamTier: {
    component: lazy(
      () => import(/* webpackChunkName: "ExamTier" */ 'views/apps/ExamTier/AddExamTier'),
    ),
  },
  Magazine: {
    component: lazy(
      () => import(/* webpackChunkName: "Magazine" */ 'views/apps/Magazine/AddMagazine'),
    ),
  },
  PYPPdf: {
    component: lazy(() => import(/* webpackChunkName: "PYPPdf" */ 'views/apps/PYPPdf/AddPYPPdf')),
  },
  LiveClass: {
    component: lazy(
      () => import(/* webpackChunkName: "LiveClass" */ 'views/apps/LiveClass/AddLiveClass'),
    ),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Instructor')}>Add Instructor</Button.Create>
      ),
    ],
  },
  Batch: {
    component: lazy(() => import(/* webpackChunkName: "Batch" */ 'views/apps/Batch/AddBatch')),
  },
  PayoutHistory: {
    title: 'Payout Status History',
    component: lazy(
      () => import(/* webpackChunkName: "PayoutHistory" */ 'views/apps/Payout/PayoutHistory'),
    ),
  },
  ProblemReport: {
    title: 'Resolve Problem Report',
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "ProblemReport" */ 'views/apps/ProblemReport/ResolveProblemReport'
        ),
    ),
  },
  EBook: {
    component: lazy(() => import(/* webpackChunkName: "EBook" */ 'views/apps/EBook/ViewEBook')),
  },
  NcertSolution: {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "NcertSolution" */ 'views/apps/NcertSolution/ViewNcertSolution'
        ),
    ),
  },
  LeadDetail: {
    title: 'Lead Details',
    component: lazy(
      () => import(/* webpackChunkName: "LeadDetail" */ 'views/apps/Lead/LeadDetail'),
    ),
  },
  Lead: {
    component: lazy(() => import(/* webpackChunkName: "LeadForm" */ 'views/apps/Lead/LeadForm')),
  },
  Referral: {
    component: lazy(
      () => import(/* webpackChunkName: "ReferralForm" */ 'views/apps/Lead/ReferralForm'),
    ),
  },
  Redeem: {
    title: 'Redeem',
    component: lazy(
      () => import(/* webpackChunkName: "Redeem" */ 'views/apps/Telecaller/RedeemForm'),
    ),
  },
  PaymentLink: {
    component: lazy(
      () =>
        import(/* webpackChunkName: "CreatePaymentLink" */ 'views/apps/Payment/CreatePaymentLink'),
    ),
  },
  Topper: {
    component: lazy(() => import(/* webpackChunkName: "Topper" */ 'views/apps/Topper/AddTopper')),
  },
};

export const queryParams = {
  drawers: withDefault(ArrayParam, [], true),
  drawerIds: withDefault(ArrayParam, [], true),
};

export interface DrawerData {
  type: DrawerTypes;
  id?: string;
  visible: boolean;
  submitted?: boolean;
  data?: any;
  title?: string | JSX.Element;
  result?: any;
}

type DrawerAction =
  | {
      type: 'OPEN_DRAWER';
      data: Omit<DrawerData, 'visible' | 'submitted'>;
    }
  | {
      type: 'HIDE_DRAWER';
      data: Pick<DrawerData, 'submitted' | 'result'>;
    }
  | {
      type: 'CLOSE_DRAWER' | 'CLOSE_ALL_DRAWER';
    };

function drawerReducer(oldDrawers: DrawerData[], action: DrawerAction) {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return [
        ...oldDrawers,
        { type: action.data.type, id: action.data.id, data: action.data.data, visible: true },
      ];
    case 'CLOSE_DRAWER': {
      const lastDrawer = oldDrawers.pop();
      return oldDrawers.map((d) => ({ ...d, submitted: d.submitted || lastDrawer?.submitted }));
    }
    case 'CLOSE_ALL_DRAWER':
      return [];
    case 'HIDE_DRAWER': {
      if (!oldDrawers.length) return oldDrawers;
      const newDrawers = [...oldDrawers];
      Object.assign(last(newDrawers) as DrawerData, {
        visible: false,
        submitted: last(newDrawers)?.submitted || action.data.submitted,
        result: action.data.result,
      });
      return newDrawers;
    }
    default:
      throw new Error('Invalid drawer action');
  }
}

export type UseDrawerQuery = UseQP<typeof queryParams>;

function initDrawersFromQuery({ drawers, drawerIds }: UseDrawerQuery['query']): DrawerData[] {
  const drawerData: DrawerData[] = [];
  drawers.forEach((drawer, index) => {
    if (!drawer) return;
    drawerData.push({
      type: drawer as DrawerTypes,
      id: drawerIds[index] || '',
      visible: true,
    });
  });
  return drawerData;
}

export type OpenDrawerFn = (type: DrawerTypes, id?: string, data?: any) => void;
export type CloseDrawerFn = (submitted?: boolean, result?: any) => void;

export function useRightDrawer() {
  const [query, setQuery] = useQueryParams(queryParams);
  const [drawers, dispatch] = useReducer(drawerReducer, query, initDrawersFromQuery);
  const topVisible = drawers[drawers.length - 1]?.visible;

  const openDrawer = useCallback<OpenDrawerFn>(
    (type, id, data) => {
      setQuery(
        (prevQuery) => ({
          drawers: [...(prevQuery.drawers ?? []), type],
          drawerIds: [...(prevQuery.drawerIds ?? []), id ?? ''],
        }),
        'pushIn',
      );
      dispatch({ type: 'OPEN_DRAWER', data: { id, type, data } });
    },
    [setQuery],
  );

  const closeAllDrawer = useCallback(() => {
    setQuery({ drawers: [], drawerIds: [] }, 'pushIn');
    dispatch({ type: 'CLOSE_ALL_DRAWER' });
  }, [setQuery]);

  const closeDrawer = useCallback<CloseDrawerFn>((submitted = false, result: any) => {
    // First hide and then remove in effect
    dispatch({ type: 'HIDE_DRAWER', data: { submitted, result } });
  }, []);

  useEffect(() => {
    if (topVisible === false) {
      setTimeout(() => {
        setQuery(
          (prevQuery) => ({
            drawers: prevQuery.drawers.slice(0, -1),
            drawerIds: prevQuery.drawerIds.slice(0, -1),
          }),
          'pushIn',
        );
        dispatch({ type: 'CLOSE_DRAWER' });
      }, 300);
    }
  }, [topVisible, setQuery]);

  return {
    drawers,
    openDrawer,
    closeDrawer,
    closeAllDrawer,
  };
}

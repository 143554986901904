import React, { useCallback, useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FaIcon } from 'components';
import { ReactComponent as LogoSvg } from 'icons/logo.svg';
import useApi from 'api';
import { logout } from 'store/reducers/auth.slice';

function Navbar(props: { showLogo?: boolean }) {
  const dispatch = useDispatch();
  const userName = useSelector((state: RootState) => state.auth.userName);
  const userRole = useSelector((state: RootState) => state.auth.userRole);
  const [api, { loading, data }] = useApi.Auth.Logout();

  const performLogout = useCallback(() => {
    googleLogout();
    api();
  }, [api]);

  useEffect(() => {
    if (!data) return;
    dispatch(logout());
  }, [data, dispatch]);

  const menu = (
    <Menu>
      <Menu.Item onClick={performLogout} disabled={loading}>
        <FaIcon icon={faPowerOff} size="lg" style={{ marginRight: 10 }} />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const getPopupContainer = useCallback(() => document.querySelector('.navbar'), []);

  return (
    <>
      {props.showLogo ? (
        <Link className="navbar-logo" to="/">
          <LogoSvg />
        </Link>
      ) : (
        <div />
      )}

      <Dropdown
        overlay={menu}
        placement="bottomRight"
        overlayClassName="navbar-overlay"
        trigger={['click']}
        getPopupContainer={getPopupContainer}
      >
        <div>
          <FaIcon icon={faUserCircle} size="lg" />
          {userRole !== 'TELECALLER' ? <span>{userName}</span> : null}
          <FaIcon icon={faChevronDown} />
        </div>
      </Dropdown>
    </>
  );
}

export default Navbar;

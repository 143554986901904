import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { pick } from 'lodash';
import { print } from 'graphql';
import { store } from 'store';
import { logger } from 'utils';
import { TIME } from 'constants/Enums';
import { logout, updateAccessToken } from 'store/reducers/auth.slice';
import possibleTypes from './possibleTypes.json';
import { REFRESH_ACCESS_TOKEN } from './apps/auth';

const baseUrl = process.env.REACT_APP_API_URL;
const { protocol } = window.location;
const BASE_API = `${protocol}//${baseUrl}`;

let apolloClient: ApolloClient<NormalizedCacheObject>;

function isTokenValid(operation: Operation) {
  const { auth } = store.getState();
  if (operation.operationName === 'GoogleLogin' && !auth.accessToken) return true;
  return (auth.tokenExpiry as number) * 1000 > new Date().getTime() + TIME.ONE_MINUTE * 5;
}

function getClient() {
  if (apolloClient) return apolloClient;

  const AuthLink = new ApolloLink((operation, forward) => {
    const { auth } = store.getState();

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${auth.accessToken}`,
      },
    }));

    return forward(operation).map((response) => {
      if (response?.errors?.[0].extensions?.status === 401) store.dispatch(logout());
      return response;
    });
  });

  const headers = {
    'Apollo-Require-Preflight': 'true',
    'X-Requested-With': `Prepp CMS ${process.env.REACT_APP_VERSION}`,
  };

  const RefreshTokenLink = new TokenRefreshLink({
    accessTokenField: 'refreshAccessToken', // graphql result field
    isTokenValidOrUndefined: async (operation) => isTokenValid(operation),
    fetchAccessToken: () => {
      const { auth } = store.getState();
      return fetch(BASE_API, {
        method: 'POST',
        headers: {
          ...headers,
          Authorization: `Bearer ${auth.refreshToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: print(REFRESH_ACCESS_TOKEN) }),
      }).then((res) => res.json());
    },
    handleResponse: () => async (res: any) => {
      if (!res.data && res.errors.length) throw new Error(res.errors[0].message);
      return res.data;
    },
    handleFetch: async (data: { accessToken: string; refreshToken: string }) => {
      store.dispatch(updateAccessToken(pick(data, ['accessToken', 'refreshToken'])));
    },
    handleError: function handleError(err) {
      logger.error({ err }, 'Error occured in refreshing access token');
      store.dispatch(logout());
      return err;
    },
  });

  apolloClient = new ApolloClient({
    link: ApolloLink.from([
      RefreshTokenLink,
      AuthLink,
      createUploadLink({ uri: BASE_API, headers }),
    ]),
    cache: new InMemoryCache({
      /** @see https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces */
      possibleTypes,
      /** @see https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization */
      typePolicies: {
        BookTopicNode: {
          keyFields: false,
        },
        BookSubtopicNode: {
          keyFields: false,
        },
        // For question linked exams
        Exam: {
          keyFields: (data) => {
            if (data.year) return `Exam:${data.id}:${data.year}`;
            return `Exam:${data.id || data.value}`;
          },
        },
      },
    }),
    credentials: 'include',
  });

  return apolloClient;
}

export default getClient();
export { getClient };

/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from 'reportWebVitals';
// NOTE: Keep styles.less before App so that our styles override antd's styles
import './styles.less';
import App from 'App';
import ErrorMessages from 'constants/ErrorMessages';
import { getClient } from 'api';
import { persistor, store } from 'store';
import { Loading } from 'components';

const apolloClient = getClient();
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<Loading size="large" />} persistor={persistor}>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider form={{ validateMessages: ErrorMessages }}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <App />
              </GoogleOAuthProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  subjectIds
  status
`;

const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  subjectIds: '[ID!]',
  nIds: '[ID!]',
  bookId: 'ID',
};
const SEARCH_ARGS = {
  ...COMMON_SEARCH_ARGS,
  subjectIds: '[ID!]',
  nIds: '[ID!]',
  bookId: 'ID',
};

const GET = gql`
  query GetChapter($id: ID!) {
    chapter(id: $id) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListChapters${toGqlVariables(LIST_ARGS)} {
    items: chapters${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
        subjects {
          id
          name { ${LANGUAGE_FIELDS} }
        }
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateChapter($input: CreateChapterInput!) {
    createChapter(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateChapter($input: UpdateChapterInput!) {
    updateChapter(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchChapters${toGqlVariables(SEARCH_ARGS)} {
    items: chapters${toGqlArgs(SEARCH_ARGS)} {
      nodes{
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  userId: 'ID',
  itemId: 'ID',
  status: 'TicketStatus',
  resolvedById: 'ID',
  itemType: 'ReportItemType',
  problems: '[ProblemType!]',
  app: 'AppType',
};

const RESOLVE_REPORT_ARGS = {
  id: 'ID!',
  status: 'TicketStatus!',
  statusRemarks: 'String',
};

const commonFields = `
  id
  message
  userId
  itemId
  status
  statusRemarks
  app
`;

const GET = gql`
  query GetProblemReport($id: ID!) {
    reportedProblem(id: $id ) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListProblemReports${toGqlVariables(LIST_ARGS)} {
    items: reportedProblems ${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        user {
          id
          name
          appOverrides
        }
        resolvedBy {
          id
          name
        }
        image {
          id
          url
        }
        ... on VideoReport {
          item {
            id
            title {
              en
            }
          }
        }
        ...on QuestionReport {
          problems
        }
        ...on CommentReport {
          item {
            hidden
          }
          problems
        }
        ...on ChatReport {
          problems
          item {
            id
            message
            user {
              id
              name
            }
            liveClass{
              id
              name {
                en
              }
            }
          }
        }
        ... on LiveClassReport {
          item {
            id
            name {
              en
            }
          }
        }
        ... on PayoutReport {
          item {
            id
          }
        }
        resolvedOn
        createdAt
        updatedAt

      }
      totalCount
    }
  }
`;

const RESOLVE = gql`
  mutation ResolveProblemReport${toGqlVariables(RESOLVE_REPORT_ARGS)}  {
    resolveProblemReport${toGqlArgs(RESOLVE_REPORT_ARGS)} {
      id
    }
  }
`;

export { GET, LIST, RESOLVE };

import { pick } from 'lodash';
import { gql } from '@apollo/client';
import { COMMON_SEARCH_ARGS, toGqlArgs, toGqlVariables } from 'api/utils';

const SEARCH_ARGS = { ...pick(COMMON_SEARCH_ARGS, ['limit', 'phrase']) };

const SEARCH = gql`
  query SearchLocations${toGqlVariables(SEARCH_ARGS)} {
    items: locations${toGqlArgs(SEARCH_ARGS)} {
      label: locationString
      city
      state
      country
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SEARCH };

import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  dateRange: 'DateRangeType',
  userId: 'ID',
  discountCode: 'String',
  referred: 'Boolean',
  referrerId: 'ID',
  app: 'AppType',
};

const LIST = gql`
  query ListPlusSubscriptions${toGqlVariables(LIST_ARGS)} {
    items: plusSubscriptions${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        user {
          id
          name
          appOverrides
        }
        amount
        status
        plan
        app
        startDate
        endDate
        discountCode
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { LIST };

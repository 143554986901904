import React, { Suspense } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { ReactComponent as LogoSvg } from 'icons/logo.svg';
import { Loading } from 'components';
import { MapRoutes, authRoutes, MapRoutesProps } from 'router';
import './styles.scoped.less';

const getRouteComponent: MapRoutesProps['render'] = ({ route, routeProps }) => {
  const { component: Component } = route;
  // @ts-ignore
  return <Component route={route} {...routeProps} />;
};

function Auth() {
  return (
    <Layout className="main">
      <Link to="/" className="logo-link">
        <LogoSvg />
      </Link>

      <Suspense fallback={<Loading size="large" />}>
        <MapRoutes
          routes={authRoutes}
          render={getRouteComponent}
          default={() => <Redirect to="/login" />}
        />
      </Suspense>
    </Layout>
  );
}

export default Auth;

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const EBOOK_ARGS = { subjectId: 'ID', class: 'Int', medium: 'BookMedium' };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...EBOOK_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...EBOOK_ARGS };

const commonFields = `
  id
  name
  status
  medium
  class
  subject {
    id
    name { ${LANGUAGE_FIELDS} }
  }
`;

const listFields = `
  ${commonFields}
  createdAt
  updatedAt
`;

const GET = gql`
  query GetEBook($id: ID!) {
    eBook(id: $id) {
      ${commonFields}
      thumbnail { ${IMAGE_FIELDS} }
      chapters {
        name
        pdfId
        pdf { ${IMAGE_FIELDS} }
      }
      oldEditionChapters {
        name
        pdfId
        pdf { ${IMAGE_FIELDS} }
      }
    }
  }
`;

const LIST = gql`
  query ListEBooks${toGqlVariables(LIST_ARGS)} {
    items: eBooks${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
        chaptersCount
        oldEditionChaptersCount
      }
      totalCount
    }
  }
`;

const SEARCH = gql`
  query SearchEBooks${toGqlVariables(SEARCH_ARGS)} {
    items: eBooks${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, SEARCH };

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  toGqlVariables,
  toGqlArgs,
  LANGUAGE_FIELDS,
} from '../utils';

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  topicId
  status
  isAbroad
`;
const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  topicId: 'ID',
  nIds: '[ID!]',
  bookId: 'ID',
  chapterId: 'ID',
};
const SEARCH_ARGS = {
  ...COMMON_SEARCH_ARGS,
  topicId: 'ID',
  nIds: '[ID!]',
  bookId: 'ID',
  chapterId: 'ID',
};

const GET = gql`
  query GetSubtopic($id: ID!) {
    subtopic(id: $id) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListSubtopics${toGqlVariables(LIST_ARGS)} {
    items: subtopics${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
        topic {
          id
          name { ${LANGUAGE_FIELDS} }
        }
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateSubtopic($input: CreateSubtopicInput!) {
    createSubtopic(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateSubtopic($input: UpdateSubtopicInput!) {
    updateSubtopic(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchSubtopics${toGqlVariables(SEARCH_ARGS)} {
    items: subtopics${toGqlArgs(SEARCH_ARGS)} {
      nodes{
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...omit(COMMON_LIST_ARGS, ['status']),
  userId: 'ID',
  itemId: 'ID',
  itemType: 'RatingItemTypes',
  stars: '[Int!]',
  feedback: '[FeedbackType!]',
  app: 'AppType',
};

const commonFields = `
    id
    stars
    user {
      id
      name
      appOverrides
    }
    itemType
    message
`;

const LIST = gql`
  query ListRatings${toGqlVariables(LIST_ARGS)} {
    items: ratings${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        ...on TestRating {
          item {
            id
            name {
              en
            }
          }
          testFeedback: feedback
        }
        ... on VideoRating {
          item {
            id
            title {
              en
            }
          }
          feedback
        }
        ... on LiveClassRating {
          item {
            id
            name {
              en
            }
          }
          feedback
        }
        ... on AppRating {
          app
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { LIST };

const COMMON_LIST_ARGS = {
  limit: 'Int',
  page: 'Int',
  sortBy: 'String',
  sortOrder: 'SortDirection',
  fromDate: 'DateTime',
  toDate: 'DateTime',
  phrase: 'String',
  ids: '[ID!]',
  status: 'Status = null', // default value to override default ACTIVE in schema
};

const COMMON_SEARCH_ARGS = {
  ...COMMON_LIST_ARGS,
  phrase: 'String!',
};

const IMAGE_FIELDS = `
  id
  url
`;

const LANGUAGE_FIELDS = `
  en
  hn
`;

const ALL_LANGUAGE_FIELDS = `
  en
  hn
  te
  mr
  bn
  ml
  gu
  kn
  ta
  or
`;

function toGqlVariables(obj: PlainObject<string>) {
  const vars: string[] = [];
  Object.entries(obj).forEach(([key, val]) => {
    vars.push(`$${key}: ${val}`);
  });
  return vars.length ? `(${vars.join(', ')})` : '';
}

function toGqlArgs(obj: PlainObject<string>) {
  const args: string[] = [];
  Object.keys(obj).forEach((key) => {
    args.push(`${key}: $${key}`);
  });
  return args.length ? `(${args.join(', ')})` : '';
}

export {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  ALL_LANGUAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
};

import { gql } from '@apollo/client';
import { IMAGE_FIELDS } from '../utils';

const UPLOAD = gql`
  mutation UploadFiles($files: [Upload!]!, $type: UploadType!, $subType: UploadSubType) {
    upload(files: $files, type: $type, subType: $subType) {
      ${IMAGE_FIELDS}
    }
  }
`;

const UPLOAD_FILE_PART = gql`
  mutation multiPartUpload($input: MultiPartUploadInput!) {
    multiPartUpload(input: $input) {
      id
      url
    }
  }
`;

const CANCEL_MULTI_PART_UPLOAD = gql`
  mutation CancelMultiPart($id: ID!) {
    cancelMultiPart(id: $id) {
      message
      success
    }
  }
`;

export { UPLOAD, UPLOAD_FILE_PART, CANCEL_MULTI_PART_UPLOAD };

import { gql } from '@apollo/client';

const faqFields = `
  id
  sno
  title
  type
  details
`;

const GET = gql`
  query GetFaq($id: ID!) {
    faq(id: $id) {
      ${faqFields}
    }
  }
`;

const LIST = gql`
  query ListFaqs($type: FaqType) {
    items: faqs(type:$type) {
      ${faqFields}
      createdAt
      updatedAt
    }
  }
`;

const CREATE = gql`
  mutation CreateFaq($input: CreateFaqInput!) {
    createFaq(input: $input) {
      ${faqFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateFaq($input: UpdateFaqInput!) {
    updateFaq(input: $input) {
      ${faqFields}
    }
  }
`;

const DELETE = gql`
  mutation DeleteFaq($id: ID!) {
    removeFaq(id: $id) {
      success
      message
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, DELETE };

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const BOOK_ARGS = { subjectId: 'ID', examIds: '[ID!]' };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...BOOK_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...BOOK_ARGS };

const commonFields = `
  id
  name
  status
  class
  type
  part
`;

const listFields = `
  ${commonFields}
  subject {
    id
    name { ${LANGUAGE_FIELDS} }
  }
  exams {
    id
    shortName { ${LANGUAGE_FIELDS} }
  }
  createdAt
  updatedAt
`;

const GET = gql`
  query GetBook($id: ID!) {
    book(id: $id) {
      ${commonFields}
      subjectId
      examIds
      chapters {
        id
        name { en }
        topics {
          id
          name { en }
          subtopics {
            id
            name { en }
          }
        }
      }
    }
  }
`;

const LIST = gql`
  query ListBooks${toGqlVariables(LIST_ARGS)} {
    items: books${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateBook($input: CreateBookInput!) {
    createBook(input: $input) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdateBook($input: UpdateBookInput!) {
    updateBook(input: $input) {
      id
    }
  }
`;

const SEARCH = gql`
  query SearchBooks${toGqlVariables(SEARCH_ARGS)} {
    items: books${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const SOLUTION_ARGS = { subjectId: 'ID', class: 'Int' };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...SOLUTION_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...SOLUTION_ARGS };

const commonFields = `
  id
  name
  status
  class
  subject {
    id
    name { ${LANGUAGE_FIELDS} }
  }
`;

const listFields = `
  ${commonFields}
  createdAt
  updatedAt
`;

const GET = gql`
  query GetNcertSolution($id: ID!) {
    ncertSolution(id: $id) {
      ${commonFields}
      thumbnail { ${IMAGE_FIELDS} }
      chapters {
        name
        pdfId
        pdf { ${IMAGE_FIELDS} }
      }
    }
  }
`;

const LIST = gql`
  query ListNcertSolutions${toGqlVariables(LIST_ARGS)} {
    items: ncertSolutions${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
        chaptersCount
      }
      totalCount
    }
  }
`;

const SEARCH = gql`
  query SearchNcertSolutions${toGqlVariables(SEARCH_ARGS)} {
    items: ncertSolutions${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, SEARCH };

import { gql } from '@apollo/client';
import { omit } from 'lodash';
import {
  COMMON_SEARCH_ARGS,
  COMMON_LIST_ARGS,
  IMAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
  LANGUAGE_FIELDS,
} from '../utils';

const EXAM_ARGS = {
  examCategoryId: 'ID',
  subjectId: 'ID',
  topicId: 'ID',
  bookId: 'ID',
  type: 'ExamType',
  externalExamId: 'Int',
};
const LIST_ARGS = { ...omit(COMMON_LIST_ARGS, ['fromDate', 'toDate']), ...EXAM_ARGS };
const SEARCH_ARGS = { ...omit(COMMON_SEARCH_ARGS, ['fromDate', 'toDate']), ...EXAM_ARGS };

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  shortName { ${LANGUAGE_FIELDS} }
  status
  externalExamId
  examDate
  type
`;

const listFields = `
  ${commonFields}
  examCategories {
    id
    name { ${LANGUAGE_FIELDS} }
  }
  relatedExams {
    id
    shortName { ${LANGUAGE_FIELDS} }
  }
  createdAt
  updatedAt
`;

const GET = gql`
  query GetExam($id:ID!) {
    exam(id:$id) {
      ${commonFields}
      relatedExamIds
      vacancies
      imageId
      image { ${IMAGE_FIELDS} }
    }
  }
`;

const LIST = gql`
  query ListExams${toGqlVariables(LIST_ARGS)} {
    items: exams${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
      }
      totalCount
    }
  }
`;

const LIST_EXAMS_AND_CATEGORIES = gql`
  query ListExamsAndCategories${toGqlVariables(LIST_ARGS)} {
    items: exams${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        examCategories {
          id
          name { ${LANGUAGE_FIELDS} }
        }
      }
    }
  }
`;

const LIST_NAMES = gql`
  query ListExamNames${toGqlVariables(LIST_ARGS)} {
    items: exams${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        shortName { ${LANGUAGE_FIELDS} }
        image {
          url
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateExam($input: CreateExamInput!) {
    createExam(input: $input) {
      ${listFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateExam($input: UpdateExamInput!) {
    updateExam(input: $input) {
      ${listFields}
    }
  }
`;

const SEARCH = gql`
  query SearchExams${toGqlVariables(SEARCH_ARGS)} {
    items: exams${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: shortName { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, LIST_NAMES, CREATE, UPDATE, SEARCH, LIST_EXAMS_AND_CATEGORIES };

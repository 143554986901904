import { startCase, lowerCase, upperFirst, camelCase, get, set, cloneDeep } from 'lodash';
import dayjs from 'dayjs';

/**
 * StartCase that don't remove special characters.
 * splits the name on whitespaces, - or _,
 */
export function specialStartCase(name: string): string {
  return name
    ?.replaceAll(/[-_]/g, ' ')
    .split(' ')
    .map((word) => (word[0]?.toUpperCase() ?? '') + word.slice(1))
    .join(' ')
    .trim();
}

export const makeOptions = (
  options: string[] | number[] | PlainObject<string>,
  capitalize = false,
): PropPresets.OptionObject[] => {
  if (!options) return [];
  if (Array.isArray(options)) {
    return options.map((option: string | number) => ({
      value: option,
      label: capitalize ? startCase(lowerCase(String(option))) : String(option),
    }));
  }
  return Object.entries(options).map(([key, val]) => ({
    value: key,
    label: capitalize ? startCase(lowerCase(val)) : val,
  }));
};

export const dateTime = (str: string): string => str && dayjs(str).format('YYYY-MM-DD HH:mm:ss');

export function getUserLocale() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

export function count(countNum: number | '-') {
  if (!countNum || countNum === '-') return countNum;
  const locale = getUserLocale();
  if (Number.isInteger(countNum) || !countNum.toFixed) {
    return new Intl.NumberFormat(locale).format(countNum);
  }
  return new Intl.NumberFormat(locale).format(Number(countNum.toFixed(2)));
}

export function getMonthString(short = false) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (short) return months.map((month) => month.slice(0, 3));
  return months;
}

export function pascalCase(str: string): string {
  return upperFirst(camelCase(str));
}

export function removeImgSrc(obj: any, paths: string[]): any {
  const cloneObj = cloneDeep(obj);
  const replaceTag = (str: string) => {
    str = str?.replaceAll(/<img.*?( src="(?!data:image).*?").*?>/g, (a, b) => a.replace(b, ''));
    return str?.replaceAll(
      /(?:<figure[^<>]+>)?<img[^<>]+data-src-id="undefined"[^<>]*>(?:<\/figure>)?/g,
      '',
    );
  };
  paths.forEach((path) => {
    const [field, ...rest] = path.split('.');

    if (!cloneObj[field]) return;

    if (Array.isArray(cloneObj[field])) {
      cloneObj[field] = cloneObj[field].map((data: any) =>
        set(data, rest, replaceTag(get(data, rest))),
      );
    }
    else {
      set(cloneObj, path, replaceTag(get(cloneObj, path)));
    }
  });
  return cloneObj;
}

export function removeLanguageField(obj: any, paths: string[]) {
  const cloneObj = cloneDeep(obj);
  paths.forEach((path) => {
    const [field, ...rest] = path.split('.');

    if (!cloneObj[field]) return;

    if (Array.isArray(cloneObj[field])) {
      cloneObj[field] = cloneObj[field].map((data: any) => set(data, rest, undefined));
    }
    else {
      set(cloneObj, path, undefined);
    }
  });
  return cloneObj;
}

export function durationStr(duration: number) {
  const hr = Math.floor(duration / 3600);
  const min = Math.floor((duration % 3600) / 60);
  const sec = Math.floor((duration % 3600) % 60);

  const data = [];
  if (hr > 0) data.push(`${hr}h`);
  if (min > 0) data.push(`${min}m`);
  if (sec > 0) data.push(`${sec}s`);
  return data.join(' ') || 0;
}

export default {
  specialStartCase,
  makeOptions,
  dateTime,
  count,
  getMonthString,
  getUserLocale,
  pascalCase,
  removeImgSrc,
};

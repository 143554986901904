import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
} from '../utils';

const commonFields = `
  id
  name
  status
`;

const GET = gql`
  query GetInstruction($instructionId: ID!) {
    instruction(id: $instructionId) {
      ${commonFields}
      text{ ${LANGUAGE_FIELDS} }
    }
  }
`;

const LIST = gql`
  query ListInstructions${toGqlVariables(COMMON_LIST_ARGS)} {
    items: instructions${toGqlArgs(COMMON_LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateInstruction($input: CreateInstructionInput!) {
    createInstruction(input: $input) {
      id
      name
    }
  }
`;

const UPDATE = gql`
  mutation UpdateInstruction($input: UpdateInstructionInput!) {
    updateInstruction(input: $input) {
      id
      name
    }
  }
`;

const SEARCH = gql`
  query SearchInstructions${toGqlVariables(COMMON_SEARCH_ARGS)} {
    items: instructions${toGqlArgs(COMMON_SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

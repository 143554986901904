import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, IMAGE_FIELDS, toGqlArgs, toGqlVariables } from 'api/utils';

const commonFields = `
  name {
    en
  }
  id
  examIds
  year
  tags
  totalTime
  totalQuestions
  status
  type
`;

const listFields = `
  ${commonFields}
  exams {
    id
    shortName {
      en
    }
  }
  pageCount
  createdAt
  updatedAt
`;

const PYP_PDF_ARGS = {
  status: 'Status = ACTIVE',
  year: 'Int',
  examId: 'ID',
  tag: 'PYPTags',
  type: 'PreviousYearTypes = null',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...PYP_PDF_ARGS };

const GET = gql`
  query GetPYPPdf($id: ID!) {
    pypPdf(id: $id) {
      ${commonFields}
      pdfId
      pdf {${IMAGE_FIELDS}}
      thumbnailId
      thumbnail {${IMAGE_FIELDS}}
    }
  }
`;

const LIST = gql`
  query ListPYPPdfs${toGqlVariables(LIST_ARGS)} {
    items: pypPdfs${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreatePYPPdf($input: CreatePYPPdfInput!) {
    createPYPPdf(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePYPPdf($input: UpdatePYPPdfInput!) {
    updatePYPPdf(input: $input) {
      ${commonFields}
    }
  }
`;

export { GET, LIST, CREATE, UPDATE };

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { flatten } from 'lodash';
import { appRoutes, telecallerRoutes, instructorRoutes, authRoutes } from 'router';
import Auth from 'views/auth';
import Main from 'views/main';

function App() {
  const isLoggedIn = useSelector((state: RootState) => Boolean(state.auth.accessToken));
  const userRole = useSelector((state: RootState) => state.auth.userRole);

  if (!isLoggedIn) return <Auth />;

  let mainEl = null;
  if (userRole === 'TELECALLER')
    mainEl = <Main routes={telecallerRoutes} key="Telecaller" showSidebar={false} />;
  else if (userRole === 'INSTRUCTOR')
    mainEl = <Main routes={instructorRoutes} key="Instructor" showSidebar={false} />;
  else mainEl = <Main routes={appRoutes} key="Admin" />;

  return (
    <>
      <Route
        key="login"
        path={flatten(authRoutes.map((route) => route.path))}
        render={() => <Redirect to="/" />}
      />

      {mainEl}
    </>
  );
}

export default App;

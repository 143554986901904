import { gql } from '@apollo/client';
import { omit } from 'lodash';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  IMAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
} from '../utils';

const VIDEO_ARGS = {
  publishedOnly: 'Boolean = false',
  publishDate: '[DateTime!]',
  type: 'VideoType',
  conversionStatus: 'VideoConversionStatus = null',
  examIds: '[ID!]',
  topicIds: '[ID!]',
  bookId: 'ID',
  subjectIds: '[ID!]',
  instructorId: 'ID',
  chapterId: 'ID',
  subtopicId: 'ID',
  tag: 'VideoTags',
  examCategoryId: 'ID',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...VIDEO_ARGS };
const SEARCH_ARGS = { ...omit(COMMON_SEARCH_ARGS, ['status']), ...VIDEO_ARGS };

const commonFields = `
  id
  title { ${LANGUAGE_FIELDS} }
  description { ${LANGUAGE_FIELDS} }
  duration
  status
  conversionStatus
  type
  publishDate
  linkedQuizId
  linkedNoteId
`;

const GET = gql`
  query GetVideo($id: ID!) {
    video(id: $id) {
      ${commonFields}
      topicId
      examIds
      thumbnailId
      fileId
      subjectId
      thumbnail { ${IMAGE_FIELDS} }
      file { ${IMAGE_FIELDS} }
      bookId
      chapterId
      subtopicId
      instructorId
      isYTVideo
      featured
      featuredTill
      tags
    }
  }
`;

const LIST = gql`
  query ListVideos${toGqlVariables(LIST_ARGS)} {
    items: videos${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        topic {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        book {
          id
          name
        }
        subject {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        exams {
          id
          shortName { ${LANGUAGE_FIELDS} }
        }
        instructor {
          id
          user {
            id
            name
          }
        }
        views
        ytViews
        avgRating
        isYTVideo
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      id
    }
  }
`;

const DOWNLOAD_YOUTUBE_VIDEO = gql`
  mutation DownloadYoutubeVideo($url: String!) {
    downloadYoutubeVideo(url: $url) {
      # fileId
      # file {
      #   id
      #   url
      #   path
      # }
      thumbnailId
      thumbnail {
        id
        url
      }
      title { ${LANGUAGE_FIELDS} }
      description { ${LANGUAGE_FIELDS} }
      duration
    }
  }
`;

const UPDATE = gql`
  mutation UpdateVideo($input: UpdateVideoInput!) {
    updateVideo(input: $input) {
      id
    }
  }
`;

const SEARCH = gql`
  query SearchVideos${toGqlVariables(SEARCH_ARGS)} {
    items: videos${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: title { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

const GET_VIDEO_URL = gql`
  query GetVideoURL($id: ID!, $type: GenerateVideoUrlType!) {
    videoUrl(id: $id, type: $type)
  }
`;

const SYNC_STATS = gql`
  mutation SyncVideoStats($id: ID!) {
    syncVideoStats(id: $id) {
      success
      message
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH, DOWNLOAD_YOUTUBE_VIDEO, GET_VIDEO_URL, SYNC_STATS };

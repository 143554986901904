import { gql } from '@apollo/client';
import { LANGUAGE_FIELDS } from '../utils';

const commonFields = `
    exams {
      id
      name {
        ${LANGUAGE_FIELDS}
      }
      updatedAt
    }
    examIds
`;

const GET = gql`
  query GetTrendingSearches {
    trendingSearches {
      ${commonFields}
    }
  }
`;

const CREATE = gql`
  mutation UpdateTrendingSearch($input: TrendingSearchInput!) {
    updateTrendingSearch(input: $input) {
      ${commonFields}
    }
  }
`;

export { GET, CREATE };

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
} from '../utils';
import { listFieldsWithParent as questionListFields } from './question';

const PRACTICE_SET_ARGS = { topicId: 'ID', subjectId: 'ID' };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...PRACTICE_SET_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...PRACTICE_SET_ARGS };

const practiceSetCommonFields = `
  id
  status
  totalQuestions
`;

const GET = gql`
  query getPracticeSet($id: ID!) {
    practiceSet(id: $id) {
      ${practiceSetCommonFields}
      topicId
      subjectId
      questionIds
      questions {
        ${questionListFields}
      }
    }
  }
`;

const LIST = gql`
  query listPracticeSets${toGqlVariables(LIST_ARGS)} {
    items: practiceSets${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${practiceSetCommonFields}
        topic {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        subject {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation AddPracticeSet($input: CreatePracticeSetInput!) {
    createPracticeSet(input: $input) {
      id
      updatedAt
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePracticeSet($input: UpdatePracticeSetInput!) {
    updatePracticeSet(input: $input) {
      id
      updatedAt
    }
  }
`;

const SEARCH = gql`
  query searchPracticeSet${toGqlVariables(SEARCH_ARGS)} {
    items: practiceSets${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: topic {
          name {
            en
          }
        }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import { gql } from '@apollo/client';
import { omit } from 'lodash';
import {
  COMMON_LIST_ARGS,
  IMAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
  LANGUAGE_FIELDS,
} from '../utils';

const MAGAZINE_ARGS = {
  examIds: '[ID!]',
  date: `DateTime`,
};
const LIST_ARGS = { ...omit(COMMON_LIST_ARGS, ['fromDate', 'toDate']), ...MAGAZINE_ARGS };

const commonFields = `
  id
  date
  status
  examIds
`;

const listFields = `
  ${commonFields}
  exams {
    id
    shortName {${LANGUAGE_FIELDS}}
  }
  pageCount
  downloadCount
  createdAt
  updatedAt
`;

const GET = gql`
  query GetMagazine($id:ID!) {
    magazine(id:$id) {
      ${commonFields}
      thumbnailId
      thumbnail {${IMAGE_FIELDS}}
      pdfId
      pdf {${IMAGE_FIELDS}}
    }
  }
`;

const LIST = gql`
  query ListMagazines${toGqlVariables(LIST_ARGS)} {
    items: magazines${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateMagazine($input: CreateMagazineInput!) {
    createMagazine(input: $input) {
      ${listFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateMagazine($input: UpdateMagazineInput!) {
    updateMagazine(input: $input) {
      ${listFields}
    }
  }
`;

export { GET, LIST, CREATE, UPDATE };

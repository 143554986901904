import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  status
`;

const listFields = `
  ${commonFields}
  createdAt
  updatedAt
`;

const GET = gql`
  query GetFormula($id: ID!) {
    formula(id: $id) {
      ${commonFields}
      expression { ${LANGUAGE_FIELDS} }
    }
  }
`;

const LIST = gql`
  query Listformulas${toGqlVariables(COMMON_LIST_ARGS)} {
    items: formulas${toGqlArgs(COMMON_LIST_ARGS)} {
      nodes {
        ${listFields}
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateFormula($input: CreateFormulaInput!) {
    createFormula(input: $input) {
      ${listFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateFormula($input: UpdateFormulaInput!) {
    updateFormula(input: $input) {
      ${listFields}
    }
  }
`;

const SEARCH = gql`
  query SearchFormulas${toGqlVariables(COMMON_SEARCH_ARGS)} {
    items: formulas${toGqlArgs(COMMON_SEARCH_ARGS)} {
      nodes{
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import { gql } from '@apollo/client';

const CREATE = gql`
  mutation CreateShortUrl($input: CreateShortUrlInput!) {
    createShortUrl(input: $input)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { CREATE };

import { gql } from '@apollo/client';

const GOOGLE_LOGIN = gql`
  mutation GoogleLogin($googleToken: String!) {
    googleLogIn(token: $googleToken) {
      accessToken
      refreshToken
      user {
        id
        name
        role: roleName
        permissions {
          allow
          deny
        }
      }
      instructorId
    }
  }
`;

const REFRESH_ACCESS_TOKEN = gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      accessToken
      refreshToken
    }
  }
`;

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export { GOOGLE_LOGIN, REFRESH_ACCESS_TOKEN, LOGOUT };

import { gql } from '@apollo/client';
import { LANGUAGE_FIELDS, toGqlArgs, toGqlVariables } from '../utils';

const ITEM_LIST_ARGS = {
  limit: 'Int = null',
  examId: 'ID',
  subjectId: 'ID!',
  topicId: 'ID!',
};

const ITEM_RESPONSE = `
  nodes {
    id
    title { ${LANGUAGE_FIELDS}}
    order
  }
`;

const GET_SUBJECTS_TOPICS = gql`
  query GetSyllabusSubjects($examId: ID) {
    syllabusSubjects(examId: $examId) {
      id
      name { ${LANGUAGE_FIELDS} }
      topics(examId: $examId, limit: null) {
        nodes {
          id
          name { ${LANGUAGE_FIELDS} }
        }
      }
    }
  }
`;

const GET_LECTURES = gql`
  query SyllabusLectures${toGqlVariables(ITEM_LIST_ARGS)} {
    items: syllabusLectures${toGqlArgs(ITEM_LIST_ARGS)} {
      ${ITEM_RESPONSE}
    }
  }
`;

const GET_QUIZZES = gql`
  query SyllabusQuizzes${toGqlVariables(ITEM_LIST_ARGS)} {
    items: syllabusQuizzes${toGqlArgs(ITEM_LIST_ARGS)} {
      nodes {
        id
        title: name { ${LANGUAGE_FIELDS}}
        order
      }
    }
  }
`;

const GET_NOTES = gql`
  query SyllabusNotes${toGqlVariables(ITEM_LIST_ARGS)} {
    items: syllabusNotes${toGqlArgs(ITEM_LIST_ARGS)} {
      ${ITEM_RESPONSE}
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation UpdateSyllabusOrder($input: SyllabusOrderInput!) {
    syllabusOrder(input: $input) {
      success
      message
    }
  }
`;

export { GET_SUBJECTS_TOPICS, GET_LECTURES, GET_QUIZZES, GET_NOTES, UPDATE_ORDER };

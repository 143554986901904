import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from 'api/utils';

const CHAT_ARGS = { classId: 'ID!', after: 'String' };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...CHAT_ARGS };

const chatFields = `
  id
  message
  user {
    id
    name
    avatar {
      url
    }
  }
  classId
  createdAt
`;

const GET = gql`
  query GetChat($id: ID!) {
    chat(id: $id) {
      ${chatFields}
    }
  }
`;

const LIST = gql`
  query ListChat${toGqlVariables(LIST_ARGS)} {
    items: chats${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${chatFields}
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`;

export { GET, LIST };

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from '../utils';

const SUBJECT_ARGS = { examIds: '[ID!]', isAbroad: 'Boolean' };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...SUBJECT_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...SUBJECT_ARGS };

const commonFields = `
  id
  name{ ${LANGUAGE_FIELDS} }
  status
  isAbroad
`;

const GET = gql`
  query GetSubject($id: ID!) {
    subject(id: $id) {
      ${commonFields}
      examIds
      imageId
      image { ${IMAGE_FIELDS} }
    }
  }
`;

const LIST = gql`
  query ListSubjects${toGqlVariables(LIST_ARGS)} {
    items: subjects${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        exams {
          id
          shortName { ${LANGUAGE_FIELDS} }
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateSubject($input: CreateSubjectInput!) {
    createSubject(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateSubject($input: UpdateSubjectInput!) {
    updateSubject(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchSubects${toGqlVariables(SEARCH_ARGS)} {
    items: subjects${toGqlArgs(SEARCH_ARGS)} {
      nodes{
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

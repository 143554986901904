import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  toGqlVariables,
  toGqlArgs,
  LANGUAGE_FIELDS,
} from '../utils';

const commonFields = `
  id
  status
  speciality
  userId
  subjectIds
`;
const LIST_ARGS = { ...COMMON_LIST_ARGS, userId: 'ID', subjectId: 'ID' };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, userId: 'ID', subjectId: 'ID' };

const GET = gql`
  query GetInstructor($id: ID!) {
    instructor(id: $id) {
      ${commonFields}
      description
    }
  }
`;

const LIST = gql`
  query ListInstructors${toGqlVariables(LIST_ARGS)} {
    items: instructors${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        subjects {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        user {
          id
          name
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateInstructor($input: CreateInstructorInput!) {
    createInstructor(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateInstructor($input: UpdateInstructorInput!) {
    updateInstructor(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchInstructors${toGqlVariables(SEARCH_ARGS)} {
    items: instructors${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: user { name }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

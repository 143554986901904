import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlVariables, toGqlArgs } from '../utils';

const TICKET_ARGS = {
  email: 'String',
  category: 'TicketCategory',
  type: 'SupportType',
  status: 'TicketStatus',
  userId: 'ID',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...TICKET_ARGS };

const commonFields = `
  id
  category
  status
  type
  message
  replies {
    message
    date
  }
`;

const GET = gql`
  query GetTicket($id: ID!) {
    ticket(id: $id) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListTickets${toGqlVariables(LIST_ARGS)} {
    items: tickets${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        user {
          id
          name
        }
        resolvedBy {
          id
          name
        }
        resolvedOn
        createdAt
        updatedAt
        email
        name
        mobile
      }
      totalCount
    }
  }
`;

const RESOLVE = gql`
  mutation ResolveTicket($id: ID!) {
    resolveTicket(id: $id) {
      ${commonFields}
      resolvedBy {
        id
        name
      }
      resolvedOn
    }
  }
`;

const REPLY = gql`
  mutation TicketReply($input: TicketReplyInput!) {
    ticketReply(input: $input) {
      ${commonFields}
    }
  }
`;

export { GET, LIST, RESOLVE, REPLY };

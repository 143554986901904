import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from '../utils';

const LIVE_CLASS_ARGS = {
  examIds: '[ID!]',
  subjectId: 'ID',
  batchIds: '[ID!]',
  language: 'Language',
  instructorId: 'ID',
  scheduledDate: 'DateTime',
  classStatus: 'LiveClassStatusType',
  scheduledDates: '[DateTime!]',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...LIVE_CLASS_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...LIVE_CLASS_ARGS };

const commonFields = `
  id
  name {
    ${LANGUAGE_FIELDS}
  }
  examIds
  subjectIds
  instructorId
  description
  status
  language
  batchIds
  classDuration
  createdAt
  updatedAt
`;

const listFields = `
  ${commonFields}
  exams {
    id
    shortName { ${LANGUAGE_FIELDS} }
  }
  subjects {
    id
    name { ${LANGUAGE_FIELDS} }
  }
  batches {
    id
    name
  }
  instructor {
    id
    user {
      id
      name
    }
  }
  thumbnail {
    ${IMAGE_FIELDS}
  }
  scheduledDate
  activeClassFields {
    roomId
    classStatus
    wentLiveOn
    activeListeners
    endAt
  }
  recording {
    duration
    originalPdfFile {
      url
    }
    annotatedPdfFile {
      url
    }
  }
`;

const GET = gql`
  query GetLiveClass($id: ID!) {
    liveClass(id: $id) {
      ${commonFields}
      exams {
        id
        shortName { ${LANGUAGE_FIELDS} }
      }
      subjects {
        id
        name { ${LANGUAGE_FIELDS} }
      }
      batches {
        id
        name
      }
      instructor {
        id
        user {
          id
          name
        }
      }
      thumbnailId
      thumbnail {
        ${IMAGE_FIELDS}
      }
      scheduledDate
      activeClassFields {
        roomId
        classStatus
        wentLiveOn
        joinedUsers {
          id
          userType
          isBlocked
          isActive
          user {
            id
            name
            avatar {
              url
            }
          }
        }
        liveChat {
          isChatEnabled
          pinnedMessageId
          pinnedMessage {
            id
            message
            user {
              id
              name
              avatar {
                id
                path
                url
              }
            }
            createdAt
          }
        }
        offset
        endAt
      }
      recording {
        canvasDataFileId
        originalPdfFileId
        annotatedPdfFileId
        duration
        canvasDataFile {
          url
        }
      }
      kubeDetails{
        udpRepeaterIp
      }
      serverDetails {
        turnUsername
        turnCredential
        turnUrl
        instructorJanusUrl
        studentJanusUrl
      }
    }
  }
`;

const GET_CANVAS_DATA = gql`
  query GetLiveClassCanvasData($roomId: String!) {
    canvasData(roomId: $roomId) {
      roomId
      type
      data
      t
      rt
      index
    }
  }
`;

const GET_SLIDE_DATA = gql`
  query GetLiveClassSlideData($roomId: String!) {
    slideData(roomId: $roomId)
  }
`;

const LIST = gql`
  query ListLiveClass${toGqlVariables(LIST_ARGS)} {
    items: liveClasses${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
        video {
          views
        }
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateLiveClass($data: CreateLiveClassInput!) {
    createLiveClass(input: $data) {
      ${commonFields}
    }
  }
`;

const UPLOAD_PDF = gql`
  mutation PdfToImage($file: Upload!, $type: UploadType!, $subType: PdfTypes!, $roomId: String!) {
    uploadLiveClassPdf(file: $file, type: $type, subType: $subType, roomId: $roomId){
      ${IMAGE_FIELDS}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateLiveClass($data: UpdateLiveClassInput!) {
    updateLiveClass(input: $data) {
      ${commonFields}
      activeClassFields {
        wentLiveOn
        classStatus
        offset
      }
    }
  }
`;

const SEARCH = gql`
  query SearchLiveClass${toGqlVariables(SEARCH_ARGS)} {
    items: liveClasses${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

const END_CLASS = gql`
mutation endClass($classId: ID!) {
  endClass(classId: $classId) {
    ${commonFields}
  }
}
`;

// NOTE: Remove this in future if not needed
const START_CLASS = gql`
  mutation StartLiveClass($id: ID!) {
    startLiveClass(id: $id) {
      ${commonFields}
      classStatus
      wentLiveOn
      examIds
      subjectIds
      topicId
      thumbnailId
      instructorId
    }
  }
`;

const SET_CHAT_STATUS = gql`
  mutation setChatStatus($id: ID!, $isChatEnabled: Boolean!) {
    setChatStatus(id: $id, isChatEnabled: $isChatEnabled) {
      ${commonFields}
      activeClassFields {
        liveChat {
          isChatEnabled
        }
      }
    }
  }
`;

const BLOCK_USER = gql`
  mutation blockLiveClassUser($id: ID!, $userId: ID!, $isBlocked: Boolean!) {
    blockLiveClassUser(id: $id, userId: $userId, isBlocked: $isBlocked) {
      ${commonFields}
      activeClassFields {
        joinedUsers {
          id
          isBlocked
        }
      }
    }
  }
`;

const PIN_MESSAGE = gql`
  mutation pinLiveClassChatMessage($classId: ID!, $messageId: ID!, $isPinned: Boolean!) {
    pinLiveClassChatMessage(classId: $classId, messageId: $messageId, isPinned: $isPinned) {
      ${commonFields}
      activeClassFields{
        liveChat{
          pinnedMessageId
        }
      }
    }
  }
`;

const START_CONTAINER = gql`
  mutation StartContainer($classId: ID!) {
    startContainer(classId: $classId)
  }
`;

const CLASS_INSTRUCTOR_USER_ID = gql`
  query GetLiveClass($id: ID!) {
    liveClass(id: $id) {
      instructor {
        id
        user {
          id
          name
        }
      }
    }
  }
`;

export {
  GET,
  LIST,
  CREATE,
  UPDATE,
  SEARCH,
  START_CLASS,
  UPLOAD_PDF,
  SET_CHAT_STATUS,
  BLOCK_USER,
  END_CLASS,
  GET_CANVAS_DATA,
  GET_SLIDE_DATA,
  PIN_MESSAGE,
  START_CONTAINER,
  CLASS_INSTRUCTOR_USER_ID,
};

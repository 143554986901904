import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  LANGUAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
  COMMON_SEARCH_ARGS,
  ALL_LANGUAGE_FIELDS,
} from '../utils';

const QUESTION_ARGS = {
  status: 'Status2 = null',
  type: 'QuestionType',
  source: 'QuestionSource',
  difficulty: 'Difficulty',
  topicId: 'ID',
  subjectId: 'ID',
  hasParent: 'Boolean',
  nIds: '[ID!]',
  options: 'Int',
  isPublic: 'Boolean',
  subtopicIds: '[ID!]',
  examIds: '[ID!]',
  year: 'Int',
  includeSubjectiveType: 'Boolean',
  availableLanguages: '[Language!]',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...QUESTION_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...QUESTION_ARGS };

const commonFields = `
  id
  source
  status
  type
  difficulty
  subtopicIds
  text { ${ALL_LANGUAGE_FIELDS} }
  availableLanguages
`;

const listFields = `
  ${commonFields}
  createdAt
  updatedAt
  topic {
    id
    name { ${LANGUAGE_FIELDS} }
  }
  subject {
    id
    name { ${LANGUAGE_FIELDS} }
  }
  subtopics {
    id
    name { ${LANGUAGE_FIELDS} }
  }
`;

const childFields = `
  ${listFields}
  slug
  exams {
    id
    shortName { ${LANGUAGE_FIELDS} }
    year
  }
  ... on ChildQuestion {
    stats {
      likes
      dislikes
      correct
      incorrect
    }
  }
  ... on SingleAnswerMCQ {
    optionShuffled
  }
  ... on MultiAnswerMCQ {
    optionShuffled
  }
`;

const listFieldsWithParent = `
  ${childFields}
  ... on ChildQuestion {
    qIndex
    parentId
    parent {
      ${listFields}
    }
  }
`;

const GET = gql`
  query GetQuestion($questionId: ID!) {
    question(id: $questionId) {
      ${commonFields}
      subjectId
      bookId
      chapterId
      subtopicIds
      topicId
      isPublic
      slug
      exams {
        id
        year
      }
      customSeo {
        title
        description
        keywords
      }
      ... on RangeQuestion {
        answerRange
      }
      ... on SingleAnswerMCQ {
        answer
        options {
          content { ${ALL_LANGUAGE_FIELDS} }
          isCorrect
        }
      }
      ... on MultiAnswerMCQ {
        answers
        options {
          content { ${ALL_LANGUAGE_FIELDS} }
          isCorrect
        }
      }
      ... on ChildQuestion {
        parentId
        externalVideos {
          title
          url
        }
        hint { ${ALL_LANGUAGE_FIELDS} }
        solution {
          text { ${ALL_LANGUAGE_FIELDS} }
          conceptIds
          formulaIds
        }
        otherSolutions {
          id
          text { ${ALL_LANGUAGE_FIELDS} }
          likes
          dislikes
        }
      }
    }
  }
`;

const LIST = gql`
  query ListQuestions${toGqlVariables(LIST_ARGS)} {
    items: questions${toGqlArgs(LIST_ARGS)} {
      nodes {
       ${childFields}
        ... on ComprehensionQuestion {
          children${toGqlArgs(LIST_ARGS.nIds ? { nIds: LIST_ARGS.nIds } : {})} {
            ${childFields}
          }
        }
      }
    }
  }
`;

const LIST_WITH_PARENT = gql`
  query ListQuestions${toGqlVariables(LIST_ARGS)} {
    items: questions${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFieldsWithParent}
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
      updatedAt
    }
  }
`;

const UPDATE = gql`
  mutation UpdateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      ${listFieldsWithParent}
    }
  }
`;

const SEARCH = gql`
  query searchQuestions${toGqlVariables(SEARCH_ARGS)} {
    items: questions${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: id
      }
    }
  }
`;

const CREATE_BY_CSV = gql`
  mutation CreateQuestionsByCsv($file: Upload!) {
    upload: createQuestionsByCsv(file: $file) {
      errors
    }
  }
`;

const UPDATE_LANGUAGE_CONTENT_BY_CSV = gql`
  mutation UpdateLanguageContentByCsv($file: Upload!) {
    upload: updateLanguageContentByCsv(file: $file) {
      errors
    }
  }
`;

const COUNT = gql`
  query CountQuestions${toGqlVariables(LIST_ARGS)} {
    data: questions${toGqlArgs(LIST_ARGS)} {
      totalCount
    }
  }
`;

export {
  GET,
  LIST,
  CREATE,
  UPDATE,
  CREATE_BY_CSV,
  SEARCH,
  LIST_WITH_PARENT,
  UPDATE_LANGUAGE_CONTENT_BY_CSV,
  listFieldsWithParent,
  COUNT,
};

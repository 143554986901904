import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  status
  examIds
  videoIds
`;
const LIST_ARGS = { ...COMMON_LIST_ARGS, videoIds: '[ID!]', examIds: '[ID!]' };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, videoIds: '[ID!]', examIds: '[ID!]' };

const GET = gql`
  query VideoPlaylist($id: ID!) {
    videoPlaylist(id: $id) {
      ${commonFields}
      thumbnailId
      thumbnail { ${IMAGE_FIELDS} }
    }
  }
`;

const LIST = gql`
  query ListVideoPlaylists${toGqlVariables(LIST_ARGS)} {
    items: videoPlaylists${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
        videos {
          totalCount
        }
        exams {
          id
          shortName { ${LANGUAGE_FIELDS} }
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateVideoPlaylist($input: CreateVideoPlaylistInput!) {
    createVideoPlaylist(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdteVideoPlaylist($input: UpdateVideoPlaylistInput!) {
    updateVideoPlaylist(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchVideoPlaylists${toGqlVariables(SEARCH_ARGS)} {
    items: videoPlaylists${toGqlArgs(SEARCH_ARGS)} {
      nodes{
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { CREATE, UPDATE, GET, LIST, SEARCH };

import React, { lazy } from 'react';
import {
  faUsers,
  faBookOpen,
  faBook,
  faPenAlt,
  faBell,
  faStar,
  faMobile,
  faLink,
  faChalkboardTeacher,
  faPlus,
  faHeadset,
} from '@fortawesome/free-solid-svg-icons';
import { VideoCameraOutlined } from '@ant-design/icons';
import { FaIcon, Button } from 'components';
import type { RouteItemChild, RouteItemChildWithPath } from './utils';

const isCD = process.env.REACT_APP_DEPLOY_MODE === 'CD';

const appRoutes: RouteItem[] = [
  {
    name: 'Exams',
    path: '/exam',
    icon: <FaIcon icon={faBookOpen} />,
    views: [
      {
        name: 'Exam Categories',
        path: '/categories',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "ListExamCategories" */ 'views/apps/ExamCategory/ListExamCategory'
            ),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('ExamCategory')}>
              Add Exam Category
            </Button.Create>
          ),
        ],
      },
      {
        name: 'Exams',
        path: 's',
        component: lazy(
          () => import(/* webpackChunkName: "ListExams" */ 'views/apps/Exam/ListExam'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Exam')}>Add Exam</Button.Create>
          ),
        ],
      },
      {
        name: 'Exam Tiers',
        path: '/tiers',
        component: lazy(
          () => import(/* webpackChunkName: "ListExamTier" */ 'views/apps/ExamTier/ListExamTier'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('ExamTier')}>Add Exam Tier</Button.Create>
          ),
        ],
        exact: true,
      },
    ],
  },
  {
    name: 'Study',
    icon: <FaIcon icon={faBookOpen} />,
    path: '/study',
    views: [
      {
        name: 'Subjects',
        path: '/subjects',
        component: lazy(
          () => import(/* webpackChunkName "ListSubjects */ 'views/apps/Subject/ListSubject'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Subject')}>Add Subject</Button.Create>
          ),
        ],
      },
      {
        name: 'Topics',
        path: '/topics',
        component: lazy(
          () => import(/* webpackChunkName "ListTopics */ 'views/apps/Topic/ListTopic'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Topic')}>Add Topic</Button.Create>
          ),
        ],
      },
      {
        name: 'Subtopics',
        path: '/subtopics',
        component: lazy(
          () => import(/* webpackChunkName "ListSubtopics */ 'views/apps/Subtopic/ListSubtopic'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Subtopic')}>Add Subtopic</Button.Create>
          ),
        ],
      },
      {
        name: 'Chapters',
        path: '/chapters',
        component: lazy(
          () => import(/* webpackChunkName "ListChapters */ 'views/apps/Chapter/ListChapter'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Chapter')}>Add Chapter</Button.Create>
          ),
        ],
      },
      {
        name: 'Concepts',
        path: '/concepts',
        component: lazy(
          () => import(/* webpackChunkName "ListConcept" */ 'views/apps/Concept/ListConcept'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Concept')}>Add Concept</Button.Create>
          ),
        ],
      },
      {
        name: 'Formulas',
        path: '/formulas',
        component: lazy(
          () => import(/* webpackChunkName "ListFormula" */ 'views/apps/Formula/ListFormula'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Formula')}>Add Formula</Button.Create>
          ),
        ],
      },
      {
        name: 'Books',
        path: '/books',
        component: lazy(() => import(/* webpackChunkName "ListBook" */ 'views/apps/Book/ListBook')),
        buttons: [() => <Button.Create href="/study/book/create">Add Book</Button.Create>],
      },
      {
        name: 'Add Book',
        path: '/book/create',
        component: lazy(() => import(/* webpackChunkName "AddBook" */ 'views/apps/Book/AddBook')),
        invisible: true,
      },
      {
        name: 'Edit Book',
        path: '/book/edit/:id',
        component: lazy(() => import(/* webpackChunkName "AddBook" */ 'views/apps/Book/AddBook')),
        exact: true,
        invisible: true,
      },
      {
        name: 'Syllabus',
        path: '/syllabus',
        component: lazy(
          () => import(/* webpackChunkName: "ListSyllabus" */ 'views/apps/Syllabus/ListSyllabus'),
        ),
        exact: true,
      },
      {
        name: 'Edit Syllabus',
        path: '/syllabus/:examId',
        component: lazy(
          () => import(/* webpackChunkName "SyllabysForm" */ 'views/apps/Syllabus/SyllabusForm'),
        ),
        exact: true,
        invisible: true,
      },
    ],
  },
  {
    name: 'Posts',
    path: '/posts',
    icon: <FaIcon icon={faBook} />,
    component: lazy(() => import(/* webpackChunkName "ListPosts" */ 'views/apps/Post/ListPost')),
    buttons: [
      ({ openDrawer }) => (
        <Button.Create onClick={() => openDrawer('Post')}>Add Post</Button.Create>
      ),
    ],
  },
  {
    name: 'Ques. & Tests',
    path: '/test',
    icon: <FaIcon icon={faPenAlt} />,
    views: [
      {
        name: 'Instructions',
        path: '/instructions',
        component: lazy(
          () =>
            import(
              /* webpackChunkName "ListInstructions" */ 'views/apps/Instruction/ListInstruction'
            ),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Instruction')}>Add Instruction</Button.Create>
          ),
        ],
      },
      {
        name: 'Questions',
        path: '/questions',
        component: lazy(
          () => import(/* webpackChunkName "ListQuestions" */ 'views/apps/Question/ListQuestion'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Question')}>Add Question</Button.Create>
          ),
        ],
      },
      {
        name: 'Tests',
        path: 's',
        component: lazy(() => import(/* webpackChunkName "ListTest" */ 'views/apps/Test/ListTest')),
        buttons: [
          () => <Button.Create href="/test/generate">Generate Test</Button.Create>,
          () => <Button.Create href="/test/create">Add Test</Button.Create>,
        ],
      },
      {
        name: 'Generate Test',
        path: '/generate',
        component: lazy(
          () => import(/* webpackChunkName "AnalyzeTest" */ 'views/apps/Test/AnalyzeTest'),
        ),
        invisible: true,
      },
      {
        name: 'Add Test',
        path: '/create',
        component: lazy(() => import(/* webpackChunkName "AddTest" */ 'views/apps/Test/AddTest')),
        invisible: true,
      },
      {
        name: 'Edit Test',
        path: '/edit/:id',
        component: lazy(() => import(/* webpackChunkName "AddTest" */ 'views/apps/Test/AddTest')),
        exact: true,
        invisible: true,
      },
      {
        name: 'Test Series',
        path: '/series',
        component: lazy(
          () =>
            import(/* webpackChunkName "ListTestSeries" */ 'views/apps/TestSeries/ListTestSeries'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('TestSeries')}>Add Test Series</Button.Create>
          ),
        ],
      },
      {
        name: 'Question Reports',
        path: '/question-reports',
        component: lazy(
          () =>
            import(
              /* webpackChunkName "ListQuestionReports" */ 'views/apps/QuestionReport/ListQuestionReport'
            ),
        ),
      },
      {
        name: 'Scholarship Entries',
        path: '/scholarship-entries',
        component: lazy(
          () =>
            import(
              /* webpackChunkName "ListScholarshipEntries" */ 'views/apps/Scholarship/ListScholarshipEntries'
            ),
        ),
      },
      {
        name: 'Test Attempts',
        path: '/:id/attempts',
        exact: true,
        invisible: true,
        component: lazy(
          () =>
            import(/* webpackChunkName "ListTestAttempts" */ 'views/apps/Test/ListTestAttempts'),
        ),
      },
      {
        name: 'Previous Year PDFs',
        path: '/pyp-pdfs',
        component: lazy(
          () => import(/* webpackChunkName "ListPDFPds" */ 'views/apps/PYPPdf/ListPYPPdfs'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('PYPPdf')}>Add PYP Pdf</Button.Create>
          ),
        ],
      },
    ],
  },
  ...(!isCD
    ? ([
      {
        name: 'Videos',
        path: '/video',
        icon: <VideoCameraOutlined />,
        views: [
          {
            name: 'Videos',
            path: 's',
            component: lazy(
              () => import(/* webpackChunkName "ListVideos" */ 'views/apps/Video/ListVideo'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Video')}>Add Video</Button.Create>
              ),
            ],
          },
          {
            name: 'Video Reports',
            path: '/reports',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListVideoReports" */ 'views/apps/VideoReport/ListVideoReport'
                ),
            ),
          },
          {
            name: 'Comment Reports',
            path: '/comment-reports',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListCommentReports" */ 'views/apps/CommentReport/ListCommentReport'
                ),
            ),
          },
          {
            name: 'Playlists',
            path: '/playlists',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListVideoPlaylists" */ 'views/apps/VideoPlaylist/ListVideoPlaylist'
                ),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('VideoPlaylist')}>
                    Add Video Playlist
                </Button.Create>
              ),
            ],
          },
        ],
      },
      {
        name: 'Batch & Class',
        path: '/batch',
        icon: <FaIcon icon={faChalkboardTeacher} />,
        views: [
          {
            name: 'Batches',
            path: 'es',
            component: lazy(
              () => import(/* webpackChunkName "ListBatches" */ 'views/apps/Batch/ListBatch'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Batch')}>Add Batch</Button.Create>
              ),
            ],
          },
          {
            name: 'Live Classes',
            path: '/live-classes',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListLiveClass" */ 'views/apps/LiveClass/ListLiveClass'
                ),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('LiveClass')}>
                    Add Live Class
                </Button.Create>
              ),
            ],
          },
          {
            name: 'Live Class Reports',
            path: '/live-class/reports',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListLiveClassReports" */ 'views/apps/LiveClassReport/ListLiveClassReport'
                ),
            ),
          },
          {
            name: 'Live Class',
            path: '/live-class/:id',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "LiveClass" */ 'views/apps/LiveClass/LiveClassWrapper'
                ),
            ),
            invisible: true,
            exact: true,
          },
          {
            name: 'Chat Reports',
            path: '/chat-reports',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListChatReports" */ 'views/apps/ChatReport/ListChatReport'
                ),
            ),
          },
        ],
      },
      {
        name: 'Prepp Plus',
        path: '/prepp-plus',
        icon: <FaIcon icon={faPlus} />,
        views: [
          {
            name: 'Payments',
            path: '/payments',
            component: lazy(
              () =>
                import(/* webpackChunkName "ListPayments" */ 'views/apps/Payment/ListPayment'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('PaymentLink')}>
                    Create Payment Link
                </Button.Create>
              ),
            ],
          },
          {
            name: 'Subscriptions',
            path: '/subscriptions',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListPlusSubscriptions" */ 'views/apps/PlusSubscription/ListPlusSubscription'
                ),
            ),
          },
          {
            name: 'Payouts',
            path: '/payouts',
            component: lazy(
              () => import(/* webpackChunkName "ListPayouts" */ 'views/apps/Payout/ListPayout'),
            ),
          },
          {
            name: 'Payout Reports',
            path: '/payout-reports',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListPayoutReports" */ 'views/apps/PayoutReport/ListPayoutReport'
                ),
            ),
          },
          {
            name: 'Promo Codes',
            path: '/promo-codes',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListPromoCodes" */ 'views/apps/PromoCode/ListPromoCode'
                ),
            ),
          },
        ],
      },
    ] as RouteItem[])
    : []),
  {
    name: 'Users',
    icon: <FaIcon icon={faUsers} />,
    path: '',
    views: [
      {
        name: 'Users',
        path: '/users',
        component: lazy(
          () => import(/* webpackChunkName: "ListUsers" */ 'views/apps/User/ListUser'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('User')}>Add User</Button.Create>
          ),
        ],
      },
      ...(!isCD
        ? ([
          {
            name: 'Instructors',
            path: '/instructors',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListInstructor" */ 'views/apps/Instructor/ListInstructor'
                ),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Instructor')}>
                    Add Instructor
                </Button.Create>
              ),
            ],
          },
          {
            name: 'Toppers',
            path: '/toppers',
            component: lazy(
              () => import(/* webpackChunkName "ListTopper" */ 'views/apps/Topper/ListTopper'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Topper')}>Add Topper</Button.Create>
              ),
            ],
          },
        ] as RouteItemChild[])
        : []),
    ],
  },
  ...(!isCD
    ? ([
      {
        name: 'Leads',
        icon: <FaIcon icon={faHeadset} />,
        path: '',
        views: [
          {
            name: 'Leads',
            path: '/leads',
            component: lazy(
              () => import(/* webpackChunkName: "ListLeads" */ 'views/apps/Lead/ListLead'),
            ),
          },
          {
            name: 'Telecallers',
            path: '/telecallers',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: "ListTelecallers" */ 'views/apps/Telecaller/ListTelecaller'
                ),
            ),
          },
        ],
      },
      {
        name: 'Notifications',
        path: '/notifications',
        icon: <FaIcon icon={faBell} />,
        views: [
          {
            name: 'App Notifications',
            path: '/app',
            exact: true,
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListNotifications" */ 'views/apps/Notification/ListNotification'
                ),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Notification')}>
                    Add Notification
                </Button.Create>
              ),
            ],
          },
          {
            name: 'Push Notification',
            path: '/push',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListPushNotifications" */ 'views/apps/PushNotification/ListPushNotification'
                ),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('PushNotification')}>
                    Add Push Notification
                </Button.Create>
              ),
            ],
          },
        ],
      },
      {
        name: 'Rating',
        path: '/ratings',
        icon: <FaIcon icon={faStar} />,
        component: lazy(
          () => import(/* webpackChunkName "ListRatings" */ 'views/apps/Rating/ListRating'),
        ),
      },
      {
        name: 'NCERT',
        icon: <FaIcon icon={faBookOpen} />,
        path: '/ncert',
        views: [
          {
            name: 'E Books',
            path: '/ebooks',
            component: lazy(
              () => import(/* webpackChunkName "ListEBook" */ 'views/apps/EBook/ListEBook'),
            ),
          },
          {
            name: 'Solutions',
            path: '/solutions',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName "ListNcertSolution" */ 'views/apps/NcertSolution/ListNcertSolution'
                ),
            ),
          },
        ],
      },
      {
        name: 'Magazines',
        path: '/magazines',
        icon: <FaIcon icon={faBookOpen} />,
        component: lazy(
          () =>
            import(/* webpackChunkName "ListMagazines" */ 'views/apps/Magazine/ListMagazines'),
        ),
        buttons: [
          ({ openDrawer }) => (
            <Button.Create onClick={() => openDrawer('Magazine')}>Add Magazine</Button.Create>
          ),
        ],
      },
      {
        name: 'Practice Sets',
        path: '/practice-sets',
        icon: <FaIcon icon={faPenAlt} />,
        component: lazy(
          () =>
            import(
              /* webpackChunkName "ListPracticeSet" */ 'views/apps/PracticeSet/ListPracticeSet'
            ),
        ),
        buttons: [
          () => <Button.Create href="/practice-set/create">Add Practice Set</Button.Create>,
        ],
      },
      {
        name: 'Add Practice Set',
        path: '/practice-set/create',
        component: lazy(
          () =>
            import(
              /* webpackChunkName "AddPracticeSet" */ 'views/apps/PracticeSet/AddPracticeSet'
            ),
        ),
        invisible: true,
      },
      {
        name: 'Edit Practice Set',
        path: '/practice-set/edit/:id',
        component: lazy(
          () =>
            import(
              /* webpackChunkName "AddPracticeSet" */ 'views/apps/PracticeSet/AddPracticeSet'
            ),
        ),
        exact: true,
        invisible: true,
      },
      {
        name: 'App',
        path: '/app',
        icon: <FaIcon icon={faMobile} />,
        views: [
          {
            name: 'Banners',
            path: '/banners',
            component: lazy(
              () => import(/* webpackChunkName "ListBanner" */ 'views/apps/Banner/ListBanner'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Banner')}>Add Banner</Button.Create>
              ),
            ],
          },
          {
            name: 'Trending Search',
            path: '/trending-search',
            component: lazy(
              () =>
                import(/* webpackChunkName "ListTrending" */ 'views/apps/Trending/ListTrending'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Trending')}>
                    Edit Trending List
                </Button.Create>
              ),
            ],
          },
          {
            name: 'FAQs',
            path: '/faqs',
            component: lazy(
              () => import(/* webpackChunkName "ListFaqs" */ 'views/apps/Faq/ListFaq'),
            ),
            buttons: [
              ({ openDrawer }) => (
                <Button.Create onClick={() => openDrawer('Faq')}>Add FAQ</Button.Create>
              ),
            ],
          },
          {
            name: 'Ticket',
            path: '/tickets',
            component: lazy(
              () => import(/* webpackChunkName "ListTicket" */ 'views/apps/Ticket/ListTicket'),
            ),
          },
        ],
      },
    ] as RouteItem[])
    : []),
  {
    name: 'Short Url',
    icon: <FaIcon icon={faLink} />,
    drawer: 'ShortUrl',
  },
];

const instructorRoutes: RouteItem[] = [
  {
    name: 'Live Classes',
    path: '/',
    icon: <FaIcon icon={faChalkboardTeacher} />,
    exact: true,
    component: lazy(
      () =>
        import(
          /* webpackChunkName "InstructorListClasses" */ 'views/apps/LiveClass/Instructor/InstructorListClasses'
        ),
    ),
  },
  {
    name: 'Live Class',
    path: '/live-class/:id',
    component: lazy(
      () => import(/* webpackChunkName "LiveClass" */ 'views/apps/LiveClass/LiveClassWrapper'),
    ),
    invisible: true,
  },
];

const telecallerRoutes: RouteItem[] = [
  {
    name: 'Dashboard',
    path: '/',
    component: lazy(
      () =>
        import(
          /* webpackChunkName "Telecaller Dashboard"  */ 'views/apps/Telecaller/TelecallerDashboard'
        ),
    ),
  },
];

const authRoutes: RouteItemChildWithPath[] = [
  {
    name: 'Login',
    component: lazy(() => import(/* webpackChunkName: "Login" */ 'views/auth/Login')),
    path: '/login',
  },
];

export { appRoutes, telecallerRoutes, authRoutes, instructorRoutes };

import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { COMMON_LIST_ARGS, COMMON_SEARCH_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...omit(COMMON_LIST_ARGS, ['status']),
  fromDate: 'DateTime!',
  toDate: 'DateTime!',
};
const SEARCH_ARGS = {
  ...omit(COMMON_SEARCH_ARGS, ['status', 'fromDate', 'toDate']),
};

const STATS = gql`
  query GetTelecallerStats($id: ID!, $dateRange: [DateTime!]!) {
    telecaller(id: $id) {
      redeemableAmount
      dailyReferralTarget
      todayPayments
      stats(dateRange: $dateRange) {
        totalLeads
        totalCalls
        totalDnp
        totalFollowUp
        totalInterested
        totalPayments
      }
    }
  }
`;

const LIST = gql`
  query ListTelecallers${toGqlVariables(LIST_ARGS)} {
    items: telecallers${toGqlArgs(omit(LIST_ARGS, ['fromDate', 'toDate']))} {
      nodes {
        id
        name
        stats(dateRange: [$fromDate, $toDate]) {
          totalLeads
          totalCalls
          totalDnp
          totalFollowUp
          totalInterested
          totalNotInterested
          totalPayments
        }
      }
      totalCount
    }
    allTelecallerStats(dateRange: [$fromDate, $toDate],telecallerIds: $ids) {
      totalLeads
      totalCalls
      totalDnp
      totalFollowUp
      totalInterested
      totalNotInterested
      totalPayments
    }
  }
`;

const SEARCH = gql`
  query SearchTelecallers${toGqlVariables(SEARCH_ARGS)} {
    items: telecallers${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { STATS, LIST, SEARCH };

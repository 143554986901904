import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { castArray } from 'lodash';
import { Menu } from 'antd';
import { ReactComponent as LogoSvg } from 'icons/logo.svg';
import { IterateRoutes, MapRoutes } from 'router';

interface SidebarProps {
  routes: RouteItem[];
  drawerData: UseDrawerReturnType;
}

function Sidebar({ routes, drawerData }: SidebarProps) {
  const getMenuLabel = ({ icon, name }: RouteItem) => (
    <>
      {icon}
      <span>{name}</span>
    </>
  );

  const items = IterateRoutes({
    routes,
    renderParent: ({ route, key, children }) => {
      if (route.invisible) return null;

      const filtered = children.filter(Boolean);
      return (
        <Menu.SubMenu key={key} title={getMenuLabel(route)}>
          {filtered}
        </Menu.SubMenu>
      );
    },
    render: ({ route, key, parent }) => {
      if (route.invisible || parent?.invisible) return null;
      const label = getMenuLabel(route);
      return (
        <Menu.Item
          key={key}
          onClick={'drawer' in route ? () => drawerData.openDrawer(route.drawer) : undefined}
        >
          {'path' in route ? (
            <Link to={generatePath(castArray(route.path)[0])}>{label}</Link>
          ) : (
            label
          )}
        </Menu.Item>
      );
    },
  });

  return (
    <>
      <Link className="sidebar-logo" to="/">
        <LogoSvg />
      </Link>
      <MapRoutes
        routes={routes}
        default={() => (
          <Menu mode="inline" theme="dark">
            {items}
          </Menu>
        )}
        render={({ parent, key }) => (
          <Menu
            mode="inline"
            theme="dark"
            defaultOpenKeys={parent ? [parent.key] : []}
            defaultSelectedKeys={[key]}
          >
            {items}
          </Menu>
        )}
      />
    </>
  );
}

export default Sidebar;

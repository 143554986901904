import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  toGqlVariables,
  toGqlArgs,
  IMAGE_FIELDS,
} from '../utils';

const commonFields = `
  id
  name
  status
  examName
  rank
`;

const GET = gql`
  query GetTopper($id: ID!) {
    topper(id: $id) {
      ${commonFields}
      avatarId
      avatar { ${IMAGE_FIELDS} }
      examCategoryId
      testimonials {
        itemType
        itemId
        thumbnailId
        thumbnail { ${IMAGE_FIELDS} }
      }
    }
  }
`;

const LIST = gql`
  query ListToppers${toGqlVariables(COMMON_LIST_ARGS)} {
    items: toppers${toGqlArgs(COMMON_LIST_ARGS)} {
      nodes {
        ${commonFields}
        examCategory {
          id
          name { en }
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateTopic($input: CreateTopperInput!) {
    createTopper(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateTopper($input: UpdateTopperInput!) {
    updateTopper(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchTopper${toGqlVariables(COMMON_SEARCH_ARGS)} {
    items: toppers${toGqlArgs(COMMON_SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import { gql } from '@apollo/client';

const HIDE = gql`
  mutation HideComment($id: ID!) {
    hideComment(id: $id) {
      id
      hidden
    }
  }
`;

const UNHIDE = gql`
  mutation UnhideComment($id: ID!) {
    unhideComment(id: $id) {
      id
      hidden
    }
  }
`;

export { HIDE, UNHIDE };

import { gql } from '@apollo/client';
import { omit } from 'lodash';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from '../utils';

const LIST_ARGS = { ...omit(COMMON_LIST_ARGS, ['fromDate', 'toDate']), isAbroad: 'Boolean' };
const SEARCH_ARGS = { ...omit(COMMON_SEARCH_ARGS, ['fromDate', 'toDate']), isAbroad: 'Boolean' };

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  status
`;

const listFields = `
  ${commonFields}
  exams {
    id
    shortName { ${LANGUAGE_FIELDS} }
  }
  createdAt
  updatedAt
`;

const GET = gql`
  query GetExamCategory($id: ID!) {
    examCategory(id:$id) {
      ${commonFields}
      shortDescription { ${LANGUAGE_FIELDS} }
      examIds
      imageId
      image { ${IMAGE_FIELDS} }
      order
    }
  }
`;

const LIST = gql`
  query ListExamCategories${toGqlVariables(LIST_ARGS)}{
    items: examCategories${toGqlArgs(LIST_ARGS)} {
      ${listFields}
    }
  }
`;

const EXAMS = gql`
  query SearchExamCategoryExams${toGqlVariables(SEARCH_ARGS)} {
    items: examCategories${toGqlArgs(SEARCH_ARGS)} {
        value: id
        label: name { ${LANGUAGE_FIELDS} }
        children: exams {
          value: id
          label: shortName { ${LANGUAGE_FIELDS} }
        }
      }
    }
`;

const CREATE = gql`
  mutation CreateExamCategory($input: CreateExamCategoryInput!) {
    createExamCategory(input: $input) {
      ${listFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateExamCategory($input: UpdateExamCategoryInput!) {
    updateExamCategory(input: $input) {
      ${listFields}
    }
  }
`;

const SEARCH = gql`
  query SearchExamCategories${toGqlVariables(SEARCH_ARGS)} {
    items: examCategories${toGqlArgs(SEARCH_ARGS)} {
      value: id
      label: name { ${LANGUAGE_FIELDS} }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH, EXAMS };

import { gql } from '@apollo/client';
import { omit } from 'lodash';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from '../utils';

const NOTIFICATION_ARGS = {
  target: 'NotificationTargets',
  phrase: 'String',
  publishDate: '[DateTime!]',
  itemType: 'NotificationType',
  app: 'AppType',
};
const LIST_ARGS = { ...omit(COMMON_LIST_ARGS, ['status']), ...NOTIFICATION_ARGS };
const SEARCH_ARGS = { ...omit(COMMON_SEARCH_ARGS, ['status']), ...NOTIFICATION_ARGS };

const itemFields = `
...on PostNotification {
  item {
    title {
      en
    }
  }
}
...on TestNotification {
  item {
    name {
      en
    }
  }
}
...on VideoNotification {
  item {
    title {
      en
    }
  }
}
...on TestSeriesNotification {
  item {
    name {
      en
    }
  }
}
...on LiveClassNotification {
  item {
    name {
      en
    }
  }
}
...on ProblemReportNotification {
  item {
    itemType
  }
}
`;

const commonFields = `
  id
  title
  imageId
  image {
    ${IMAGE_FIELDS}
  }
  examCategoryIds
  itemType
  itemId
  target
  publishDate
  app
`;

const GET = gql`
  query GetNotification($id: ID!) {
    notification(id: $id) {
      ${commonFields}
      text
    }
  }
`;

const LIST = gql`
  query ListNotifications${toGqlVariables(LIST_ARGS)} {
    items: notifications${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        ${itemFields}
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateNotification($data: CreateNotificationInput!) {
    createNotification(input: $data) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateNotification($data: UpdateNotificationInput!) {
    updateNotification(input: $data) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchNotification${toGqlVariables(SEARCH_ARGS)} {
    items: notifications${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: title
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

import React, { useCallback, useState } from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps, Tooltip, TooltipProps } from 'antd';
import { EyeOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Link, LinkProps } from 'react-router-dom';
import FaIcon from './FaIcon';

export interface ButtonProps extends Omit<AntButtonProps, 'href'> {
  href?: LinkProps['to'];
  tooltip?: string | TooltipProps;
  disabledTooltip?: string | TooltipProps;
}

function Button(props: ButtonProps) {
  const { href, tooltip, disabledTooltip, ...rest } = props;
  let Btn = <AntButton {...rest} />;
  if (href) {
    if (typeof href === 'string' && !href.startsWith('/')) {
      Btn = React.cloneElement(Btn, { href, target: '_blank' });
    }
    else {
      Btn = (
        <Link to={href} className="link-ant-btn">
          {Btn}
        </Link>
      );
    }
  }
  if (tooltip || disabledTooltip) {
    let activeTooltip = rest.disabled ? disabledTooltip : tooltip;
    if (!activeTooltip || typeof activeTooltip === 'string') {
      activeTooltip = { title: activeTooltip };
    }
    Btn = <Tooltip {...activeTooltip}>{Btn}</Tooltip>;
  }
  return Btn;
}

export type CustomButtonProps = Omit<ButtonProps, 'type' | 'icon'>;

Button.Edit = (props: CustomButtonProps) => (
  <Button className="edit-button" type="primary" icon={<EditOutlined />} {...props} />
);

Button.View = (props: CustomButtonProps) => (
  <Button className="view-button" type="primary" icon={<EyeOutlined />} {...props} />
);

Button.Create = (props: CustomButtonProps) => (
  <Button className="create-button" type="primary" icon={<PlusOutlined />} {...props} />
);

Button.Delete = (props: CustomButtonProps) => (
  <Button className="delete-button" type="primary" danger icon={<DeleteOutlined />} {...props} />
);

type CopyButtonProps = Omit<CustomButtonProps, 'onClick'> & {
  label: string;
  copyText: string | (() => string);
};

Button.Copy = function CopyButton({ label, copyText, ...btnProps }: CopyButtonProps) {
  const [active, setActive] = useState(false);
  return (
    <Tooltip
      title={active ? 'Copied!' : `Copy ${label}`}
      onVisibleChange={useCallback((visible) => {
        if (!visible) setTimeout(() => setActive(false), 500);
      }, [])}
    >
      <Button
        className="copy-button"
        icon={<FaIcon icon={faCopy} />}
        onClick={useCallback(() => {
          setActive(true);
          const text = typeof copyText === 'string' ? copyText : copyText();
          navigator.clipboard.writeText(text);
        }, [copyText])}
        {...btnProps}
      />
    </Tooltip>
  );
};

export default Button;

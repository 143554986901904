import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  toGqlVariables,
  toGqlArgs,
  LANGUAGE_FIELDS,
} from '../utils';

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  status
  subjectIds
  examIds
  isAbroad
`;
const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  subjectId: 'ID',
  examIds: '[ID!]',
  nIds: '[ID!]',
  bookId: 'ID',
  chapterId: 'ID',
};
const SEARCH_ARGS = {
  ...COMMON_SEARCH_ARGS,
  subjectId: 'ID',
  examIds: '[ID!]',
  nIds: '[ID!]',
  bookId: 'ID',
  chapterId: 'ID',
};

const GET = gql`
  query GetTopic($id: ID!) {
    topic(id: $id) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListTopics${toGqlVariables(LIST_ARGS)} {
    items: topics${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
        subjects {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        exams {
          id
          shortName { ${LANGUAGE_FIELDS} }
        }
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateTopic($input: CreateTopicInput!) {
    createTopic(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateTopic($input: UpdateTopicInput!) {
    updateTopic(input: $input) {
      ${commonFields}
    }
  }
`;

const SEARCH = gql`
  query SearchTopics${toGqlVariables(SEARCH_ARGS)} {
    items: topics${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

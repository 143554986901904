import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from 'api/utils';

const PROMO_CODE_ARGS = {
  availedById: 'ID',
  status: 'PromoCodeStatus',
  partnerId: 'ID',
  code: 'String',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...PROMO_CODE_ARGS };

const commonFields = `
  id
  code
  status
  partnerId
`;

const listFields = `
  ${commonFields}
  createdAt
  updatedAt
`;

const LIST = gql`
  query ListPromoCode${toGqlVariables(LIST_ARGS)} {
    items: promoCodes${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
          availedBy {
            id
            name
          }
          partner {
            name
            id
          }
      }
      totalCount
    }
  }
`;

export default LIST;
